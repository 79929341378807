<template>
    <NavbarMain />
    <PresentationSection />
    <CardSection />
    <WidePhotoComponent />
    <FooterPage />
</template>

<script>
import CardSection from '@/components/CardSection.vue';
import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import PresentationSection from '@/components/PresentationSection.vue';
import WidePhotoComponent from '@/components/WidePhotoComponent.vue';

export default {
    components: {
        NavbarMain,
        PresentationSection,
        CardSection,
        WidePhotoComponent,
        FooterPage
    }
}

</script>

<style></style>