<template>
  <div id="homepage-header">
    <div class="content container" style="text-align: left;">
      <div v-html="banner_title"/>
      <RouterLink v-if="banner_button_link" :to="banner_button_link" class="no-underline">
        {{ banner_button_text }}
      </RouterLink>
    </div>

    <span id="scroll-down" @click="scrollToBottom">
      <img :src="icon_arrow_white" alt="">
    </span>

    <div class="video-wrapper">
      <!-- <video preload="auto" autoplay muted loop :src="videoUrl"></video> -->
      <!-- <video preload="auto" autoplay muted loop :src="banner_image"></video> -->
      <img :src="banner_image" alt="">
    </div>
  </div>
</template>


<script>
export default {
  props: {
    banner_image: String,
    banner_title: String,
    banner_button_text: String,
    banner_button_link: String,
  },
  data() {
    return {
      videoUrl: require('@/assets/wind_turbine_video.mp4'),
      icon_arrow_white: require('@/assets/icons/icon_arrow-white.png'),
      icon_arrow_white1x: require('@/assets/icons/icon_arrow-white.png'),
      icon_arrow_white2x: require('@/assets/icons/icon_arrow-white@2x.png'),

    };
  },
  
  methods: {
    getField(field) {
      return field;
    },
    getTemplateDirectoryUri() {
      return 'Simulated template directory URI';
    },
    scrollToBottom() {
      const homepageHeader = document.getElementById('homepage-header');
      const height = homepageHeader.clientHeight;

      window.scrollTo({
        top: height,
        behavior: 'smooth',
      });
    },
  },
};
</script>


<style>
@import '@/css/main.css';

.no-underline {
  text-decoration: none;
}
</style>
