<template>
    <NavbarMain />

    <PresentationAnnouncements v-if="currentLanguage === 'pl'" :photo="first_section_image_pl" :title="first_section_title_pl"
        :content="first_section_content_pl" :content-link="second_section_button_content_pl" :link-x="second_section_button_link_pl" />
    <PresentationAnnouncements v-else :photo="first_section_image_en" :title="first_section_title_en"
        :content="first_section_content_en" :content-link="second_section_button_content_en" :link-x="second_section_button_link_en" />

    <!-- <div class="columns-4-section">
        <CtaSectionExtern v-if="currentLanguage === 'pl'" :content="second_section_title_pl"
            :content-link="second_section_button_content_pl" :link-x="second_section_button_link_pl" />
        <CtaSectionExtern v-else :content="second_section_title_en" :content-link="second_section_button_content_en"
            :link-x="second_section_button_link_en" />
    </div> -->

    <WidePhotoComponent v-if="currentLanguage === 'pl'" :photo="third_section_image_pl" :content="third_section_content_pl" />
    <WidePhotoComponent v-else :photo="third_section_image_en" :content="third_section_content_en" />

    <FooterPage />
</template>

<script>
import axios from 'axios';
import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import WidePhotoComponent from '@/components/WidePhotoComponent.vue';
import PresentationAnnouncements from '@/components/PresentationAnnouncements.vue';

export default {
    components: {
    NavbarMain,
    FooterPage,
    WidePhotoComponent,
    PresentationAnnouncements
},
    data() {
        return {
            announcementData: {
                items_pl: [{ first_section_image: '', first_section_title: '', first_section_content: '', second_section_title: '', second_section_button_content: '', second_section_button_link: '', third_section_image: '', third_section_content: '' }],
                items_en: [{ first_section_image: '', first_section_title: '', first_section_content: '', second_section_title: '', second_section_button_content: '', second_section_button_link: '', third_section_image: '', third_section_content: '' }]
            },
        

            first_section_image_pl: '',
            first_section_title_pl: '',
            first_section_content_pl: '',
            second_section_title_pl: '',
            second_section_button_content_pl: '',
            second_section_button_link_pl: '',
            third_section_image_pl: '',
            third_section_content_pl: '',

            first_section_image_en: '',
            first_section_title_en: '',
            first_section_content_en: '',
            second_section_title_en: '',
            second_section_button_content_en: '',
            second_section_button_link_en: '',
            third_section_image_en: '',
            third_section_content_en: '',
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = '343ea0306525d6efa98f3d15cdb2f0a5db59df4f';
        const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;

        axios.get(`${API_URL}/api/announcements-content`, {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.announcementData = response.data || { items_pl: [], items_en: [] };
                console.log(this.announcementData)
                if (this.announcementData.items_pl[0].active === true) {
                    this.first_section_title_pl = this.announcementData.items_pl[0].first_section_title;
                    this.first_section_content_pl = this.announcementData.items_pl[0].first_section_content;
                    this.first_section_image_pl = API_URL + this.announcementData.items_pl[0].first_section_image;
                    this.second_section_title_pl = this.announcementData.items_pl[0].second_section_button_title;
                    this.second_section_button_content_pl = this.announcementData.items_pl[0].second_section_button_content;
                    this.second_section_button_link_pl = this.announcementData.items_pl[0].second_section_button_link;
                    this.third_section_image_pl = API_URL + this.announcementData.items_pl[0].third_section_image;
                    this.third_section_content_pl = this.announcementData.items_pl[0].third_section_content;
                }
                console.log(this.first_section_image_pl)

                if (this.announcementData.items_en[0].active === true) {
                    this.first_section_title_en = this.announcementData.items_en[0].first_section_title;
                    this.first_section_content_en = this.announcementData.items_en[0].first_section_content;
                    this.first_section_image_en = API_URL + this.announcementData.items_en[0].first_section_image;
                    this.second_section_title_en = this.announcementData.items_en[0].second_section_button_title;
                    this.second_section_button_content_en = this.announcementData.items_en[0].second_section_button_content;
                    this.second_section_button_link_en = this.announcementData.items_en[0].second_section_button_link;
                    this.third_section_image_en = API_URL + this.announcementData.items_en[0].third_section_image;
                    this.third_section_content_en = this.announcementData.items_en[0].third_section_content;
                }
            })
            .catch(error => {
                console.error('Error fetching Announcements data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
</script>

<style>
.columns-4-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
    gap: 10px;
    padding-left: 3rem;
}

@media all and (max-width: 850px) {
    .columns-4-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}
</style>