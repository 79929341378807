<template>
    <NavbarMain />
    <PresentationSection />
    <WidePhotoComponent />
    <div class="columns-4-section">
        <CtaSection />
    </div>
    <FooterPage />
</template>

<script>
import CtaSection from '@/components/CtaSection.vue';
import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import PresentationSection from '@/components/PresentationSection.vue';
import WidePhotoComponent from '@/components/WidePhotoComponent.vue';

export default {
    components: {
    NavbarMain,
    PresentationSection,
    WidePhotoComponent,
    FooterPage,
    CtaSection,
}
}
</script>

<style>
.columns-4-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
    gap: 10px;
    padding: auto;
}

@media all and (max-width: 850px) {
    .columns-4-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}
</style>