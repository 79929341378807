<template>
    <section class="page-section section-cards" :style="{ marginLeft: '5%', height: 'auto' }">
        <div class="container">
            <div class="cards-wrapper" :style="{ width: '100%' }">
                <div class="card-element" :style="{ width: '100%' }">
                    <div class="card-wrapper" :style="{ width: '100%' }">
                        <div class="card-content">
                            <h2 v-if="currentLanguage === 'pl'">{{ contentPl }}</h2>
                            <h2 v-else>{{ contentEn }}</h2>
                            <div v-if="currentLanguage === 'pl'" class="tab-content form-tabs">

                                <form @submit.prevent="submitForm">
                                    <div class="column-2-inputs">
                                        <div class="form-item">
                                            <label v-show="!firstName" for="firstName"
                                                @click="hideLabel('firstName')">Imię</label>
                                            <input type="text" id="firstName" v-model="firstName" required>
                                        </div>
                                        <div class="form-item">
                                            <label v-show="!lastName" for="lastName"
                                                @click="hideLabel('lastName')">Nazwisko</label>
                                            <input type="text" id="lastName" v-model="lastName" required>
                                        </div>
                                        <div class="form-item">
                                            <label v-show="!phone" for="phone" @click="hideLabel('phone')">Telefon</label>
                                            <input type="tel" id="phone" v-model="phone" required>
                                        </div>
                                        <div class="form-item">
                                            <label v-show="!email" for="email" @click="hideLabel('email')">Email</label>
                                            <input type="email" id="email" v-model="email" required>
                                        </div>
                                        <div class="form-item">

                                            <label v-show="!message" for="message" @click="hideLabel('message')">Treść
                                                Wiadomości</label>
                                            <textarea id="message" v-model="message" required></textarea>
                                        </div>
                                        <div class="form-item">
                                            <label for="gdpr">
                                                <input type="checkbox" id="gdpr" v-model="gdpr" required>
                                                Administratorem przesłanych danych osobowych będzie II Renewable Energy
                                                Supply Poland sp. z o.o. z siedzibą w Jankach, przy Plac Szwedzki 3, 05-090
                                                Raszyn. Twoje dane będą przetwarzane w celu przygotowania, przesyłania oraz
                                                archiwizacji oferty współpracy. Więcej informacji na ten temat znajduje się
                                                w <a href="/gdpr" target="_blank">Polityce prywatności</a>.
                                            </label>

                                        </div>
                                    </div>
                                    <br />

                                    <button type="submit" style="margin: 0; border: none; background-color: none;">
                                        <span class="button next-tab"
                                            style="padding: 1.4rem; display: inline-block; width: auto;">Submit</span>
                                    </button>

                                </form>
                            </div>
                            <div v-else class="tab-content form-tabs" style="height: auto;">

                                <form @submit.prevent="submitForm">
                                    <div class="column-2-inputs">
                                        <div class="form-item">
                                            <label v-show="!firstName" for="firstName" @click="hideLabel('firstName')">First
                                                Name</label>
                                            <input type="text" id="firstName" v-model="firstName" required>
                                        </div>
                                        <div class="form-item">
                                            <label v-show="!lastName" for="lastName" @click="hideLabel('lastName')">Last
                                                Name</label>
                                            <input type="text" id="lastName" v-model="lastName" required>
                                        </div>
                                        <div class="form-item">
                                            <label v-show="!phone" for="phone" @click="hideLabel('phone')">Phone</label>
                                            <input type="tel" id="phone" v-model="phone" required>
                                        </div>
                                        <div class="form-item">
                                            <label v-show="!email" for="email" @click="hideLabel('email')">Email</label>
                                            <input type="email" id="email" v-model="email" required>
                                        </div>
                                        <div class="form-item">

                                            <label v-show="!message" for="message"
                                                @click="hideLabel('message')">Message</label>
                                            <textarea id="message" v-model="message" required></textarea>
                                        </div>
                                        <div class="form-item">
                                            <label for="gdpr">
                                                <input type="checkbox" id="gdpr" v-model="gdpr" required>
                                                The administrator of your personal data is II Renewable Energy Supply Poland
                                                sp. z o.o. with its headquarters in Janki at Plac Szwedzki 3, 05-090 Raszyn.
                                                Your personal data will be processed for the purpose of preparing, sending
                                                and archiving the commercial offer. More information on the processing of
                                                your personal data can be found in the <a href="/gdpr"
                                                    target="_blank">Privacy Policy</a>.
                                            </label>

                                        </div>
                                    </div>
                                    <br />

                                    <button type="submit" style="margin: 0; border: none; background-color: none;">
                                        <span class="button next-tab"
                                            style="padding: 1.4rem; display: inline-block; width: auto;">Submit</span>
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            contentEn: "Fill out the form below, and one of our team representatives will reach out to you personally to guide you through the exciting next steps:",
            contentPl: "WYPEŁNIJ PONIŻSZY FORMULARZ, A JEDEN Z PRZEDSTAWICIELI NASZEGO ZESPOŁU SKONTAKTUJE SIĘ Z TOBĄ OSOBIŚCIE, ABY POPROWADZIĆ CIĘ PRZEZ KOLEJNE EKSCYTUJĄCE KROKI",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            message: "",
            gdpr: false,
        };
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    methods: {
        submitForm() {
            // Handle form submission logic here
            // You can use any of the mentioned sources for additional form handling details.
        },
        hideLabel(inputId) {
            // Hide the label when clicked
            this[inputId] = true;
        },
        showLabel(inputId) {
            // Show the label when there is input in the textarea
            if (this[inputId]) {
                this[inputId] = false;
            }
        },
    },
    mounted() {
        console.log(this.firstName + " " + this.lastName + " " + this.phone + " " + this.email + " " + this.message)

        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
</script>

<style>
@import '@/css/cardSection.css';
@import '@/css/main.css';

.form-columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form-column {
    width: 48%;
    /* Adjust the width as needed */
    margin-bottom: 15px;
}

.gdpr-submit-container {
    width: 100%;
    text-align: center;
    /* Center content horizontally */
    margin-top: 15px;
}

.gdpr-checkbox {
    margin-top: 10px;
}

.submit-button {
    text-align: center;
}

.column-2-final {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
}

.column-2-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
}

@media all and (max-width: 991px) {
    .column-2-inputs {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        /* Center the columns horizontally */
        align-items: center;
    }
}

input textarea {
    max-width: 90%;
}

.cards-wrapper {
    height: auto;
}</style>