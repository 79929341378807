<template>
    <section class="page-section section-photo-cards">
        <div class="container">
            <div class="cards-wrapper" :class="{ 'cards-wrapper2': true }">
                <div class="card-element blue">
                    <a :href="button_link" :title="button_text" class="card-wrapper">
                        <div class="card-image">
                            <img :src="section_image" alt="image" />

                        </div>
                        <div class="card-content">
                            <!-- <h2 v-if="button_title !== '' || button_title !== 'OUR OFFER' || button_title !== 'NASZA OFERTA'">
                                <div v-html="button_title" />
                            </h2> -->
                            <div v-html="button_text" class="white-text"></div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    props: {
        section_image: String,
        button_title: String,
        button_text: String,
        button_link: String,
    },
    data() {
        return {
            image: require('@/assets/404.jpg'),
        }
    },
};
</script>

<style>
@import '@/css/photoCardsSection.css';

.white-text h5 {
    color: white;
}

/* @media all and (min-width: 200px) {
    .section-photo-cards {
        height: 40vh;
        padding: auto;
        margin-bottom: 2rem;
    }
} */

@media all and (min-width: 1200px) {
    .cards-wrapper2 {
        height: auto;
        max-height: 80vh;
        /* padding-bottom: 10rem; */
        /* margin-bottom: 2rem; */
    }
}



@media all and (min-width: 1800px) {
    .cards-wrapper2 {
        height: auto;
        max-height: 70vh;
        /* padding-bottom: 10rem; */
        /* margin-bottom: 2rem; */
    }
}

@media all and (min-width: 2300px) {
    .cards-wrapper2 {
        height: auto;
        max-height: 65vh;
        /* padding-bottom: 10rem; */
        /* margin-bottom: 2rem; */
    }
}

@media all and (min-width: 800px) and (max-width: 1199px) {
    .cards-wrapper2 {
        height: auto;
        max-height: 90vh;
    }
}
</style>

