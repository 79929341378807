<template>
    <section class="page-section section-cards" :style="{ marginLeft: '5%', height: '100%' }">
        <div class="container">
            <div class="cards-wrapper" :style="{ width: '100%', height: '100%' }">
                <div class="card-element" :style="{ width: '100%', height: '100%' }">
                    <div class="card-wrapper" :style="{ width: '100%', height: '100%' }">
                        <div class="card-content" :style="{ height: '100%', overflow: 'auto' }">
                            <h2>
                                <div v-html="contentTitle" />
                            </h2>
                            <div v-html="contentSentance" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
  
<script>
export default {
    props: {
        contentTitle: String,
        contentSentance: String,
        buttonText: String,
        buttonLink: String,
    },
    data() {
        return {
            cards: [],
            // contentTitle: "TItle content",
            // contentSentance: "Sentance content for a card components ",
            // contentLink: "100% energie regenerabile",
            // linkX: "/faq"
        };
    },

};
</script>

<style>
@import '@/css/cardSection.css';

.cards-wrapper {
    display: flex;
    align-items: stretch;
    /* Ensures equal height for all cards */
    width: 100%;
    height: 32rem;
}
</style>