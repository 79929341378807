<template>
    <NavbarMain />

    <PresentationSection v-if="currentLanguage === 'pl'" :photo="first_section_image_pl" :title="first_section_title_pl" :content="first_section_content_pl"/>
    <PresentationSection v-else :photo="first_section_image_en" :title="first_section_title_en" :content="first_section_content_en"/>
    
    <IndustrialClientsForm />

    <FooterPage />
</template>

<script>
import axios from 'axios';

import FooterPage from '@/components/FooterPage.vue';
import IndustrialClientsForm from '@/components/IndustrialClientsForm.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import PresentationSection from '@/components/PresentationSection.vue';

export default {
    components: {
        NavbarMain,
        PresentationSection,
        IndustrialClientsForm,
        FooterPage
    },
    data() {
        return {
            contactIndustrialClientsData: {
                items_pl: [{first_section_image: '', first_section_title: '', first_section_content: ''}],
                items_en: [{first_section_image: '', first_section_title: '', first_section_content: ''}]
            },
            first_section_image_pl: '',
            first_section_title_pl: '',
            first_section_content_pl: '',
        
            first_section_image_en: '',
            first_section_title_en: '',
            first_section_content_en: '',
        
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = '343ea0306525d6efa98f3d15cdb2f0a5db59df4f';
        const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;

        axios.get(`${API_URL}/api/contact-industrial-client-content`, {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.contactIndustrialClientsData = response.data || { items_pl: [], items_en: [] };

                if (this.contactIndustrialClientsData.items_pl[0].active === true) {
                    this.first_section_title_pl = this.contactIndustrialClientsData.items_pl[0].first_section_title;
                    this.first_section_content_pl = this.contactIndustrialClientsData.items_pl[0].first_section_content;
                    this.first_section_image_pl = API_URL + this.contactIndustrialClientsData.items_pl[0].first_section_image;
                    
                }
                if (this.contactIndustrialClientsData.items_en[0].active === true) {
                    this.first_section_title_en = this.contactIndustrialClientsData.items_en[0].first_section_title;
                    this.first_section_content_en = this.contactIndustrialClientsData.items_en[0].first_section_content;
                    this.first_section_image_en = API_URL + this.contactIndustrialClientsData.items_en[0].first_section_image;
                    
                }
            })
            .catch(error => {
                console.error('Error fetching Contact Industrial Clients data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}

</script>

<style></style>