<template>
    <div id="page-header">
        <div class="container-fluid">
            <div class="page-header-wrapper">
                <div class="page-header-content">
                    <h1 v-if="currentLanguage === 'ro'">Eroare 404</h1>
                    <h1 v-else>404 Error</h1>

                    <p v-if="currentLanguage === 'ro'">Din păcate această pagină nu există, te rugăm să revii către pagina
                        principală</p>
                    <p v-else>Unfortunately this page does not exist, please return to the home page</p>

                    <p v-if="currentLanguage === 'ro'"><a href="/" class="button">Înapoi către prima pagină</a></p>
                    <p v-else><a href="/" class="button">Back to the homepage</a></p>
                </div>
                <div class="page-header-image">
                    <img :src=image alt="photo">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            image: require('@/assets/404.jpg'), 
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}

</script>

<style>
@import '@/css/errorSection.css';
</style>