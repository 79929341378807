import { createRouter, createWebHistory } from 'vue-router'

import HomepageView from '@/views/HomepageView.vue'
// import GDPRView from '@/views/GDPRView.vue'
import ErrorView from '@/views/ErrorView'
import GdprView from '@/views/GdprView'
import FaqView from '@/views/FaqView'
import AboutUsView from '@/views/AboutUsView'
import ContactUsView from '@/views/ContactUsView'
import GeneralTemplateView from '@/views/GeneralTemplateView'
import HouseholdClientsView from '@/views/HouseholdClientsView'
import IndustrialClientsView from '@/views/IndustrialClientsView'
import StrategyView from '@/views/StrategyView'
import ClientsAndOffersView from '@/views/ClientsAndOffersView'
import ContactIndustrialClientsView from '@/views/ContactIndustrialClientsView'
import AnnouncementsView from '@/views/AnnouncementsView'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomepageView
    },
    {
        path: '/gdpr',
        name: 'gdpr',
        component: GdprView
    },
    {
        path: '/faq',
        name: 'faq',
        component: FaqView
    },
    {
        path: '/about-us',
        name: 'aboutUs',
        component: AboutUsView
    },
    {
        path: '/strategy',
        name: 'strategy',
        component: StrategyView
    },
    {
        path: '/clients-and-offers',
        name: 'clientsAndOffers',
        component: ClientsAndOffersView
    },
    {
        path: '/contact-us',
        name: 'contactUs',
        component: ContactUsView
    },
    {
        path: '/general',
        name: 'general',
        component: GeneralTemplateView
    },
    {
        path: '/householdClients',
        name: 'householdClients',
        component: HouseholdClientsView
    },
    {
        path: '/industrialClients',
        name: 'industrialClients',
        component: IndustrialClientsView
    },
    {
        path: '/contact-industrial-clients',
        name: 'contactIndustrialClients',
        component: ContactIndustrialClientsView
    },
    {
        path: '/announcements',
        name: 'announcements',
        component: AnnouncementsView
    },
    { 
        path: '/:catchAll(.*)',
        component: ErrorView
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;