<template>
    <section :class="'page-section section-cta '">
        <div class="content container">
            <h4>
                <div v-html="content" class="content"/>

            </h4>
            <RouterLink v-if="linkX" :to="linkX"  class="no-underline">
                {{ contentLink }}
            </RouterLink>
        </div>
    </section>
</template>
  
<script>
export default {
    props: {
        content: String,
        contentLink: String,
        linkX: String,
    },
    
};
</script>
  
<style >
@import '@/css/main.css';
</style>
  