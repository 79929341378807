<template>
    <section class="page-section section-photo-cards">
        <div class="container" :style="{padding: '3rem'}">
            <div class="content">
                <h2>
                    <div v-html="section_title" />
                </h2>

                <strong><h3><div v-html="section_content" /></h3></strong>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    props: {
        section_title: String,
        section_content: String,
     
    },
    data() {
        return {
            image: require('@/assets/404.jpg'),
        }
    },
};
</script>

<style>
@import '@/css/photoCardsSection.css';

.content {
    text-align: left;
    /* width: 90%; */
}
</style>