<template>
    <section class="page-section section-cta" style="width: 100%; height: auto;">
        <div class="container" style="width: 100%; height: auto;">
            <div class="cards-wrapper cards-col-1" style="width: 100%; height: auto;">
                <div class="card-element" style="width: 100%; height: auto;">
                    <div class="card-wrapper" style="width: 100%; height: auto;">
                        <div class="card-content" style="width: 100%; height: auto;">
                            <h2>
                                {{ section_title }}
                            </h2>
                            <div v-html="gdpr_policy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        section_title: String,
        gdpr_policy: String
    },
}
</script>

<style>
@import '@/css/gdprContentSection.css';

.card-content p {
    font-size: 14px;
    /* Adjust the size as needed */
}
</style>
