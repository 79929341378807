<template>
    <div id="page-header">
        <div class="container-fluid">
            <div class="page-header-wrapper">
                <div class="page-header-content" :style="{ textAlign: 'left'}">
                    <h1 v-if="isSingle">{{ title }}</h1>
                    <h1 v-else>{{ title }}</h1>
                    <br />
                    <h5>
                        <div v-if="!isSingle" v-html="content" />
                    </h5>

                    <section :class="'page-section section-cta '">
                        <div class="content container">
                            <a :href="linkX" target="_blank" style="padding: 20px;">{{ contentLink }}</a>
                        </div>
                    </section>

                </div>
                <div v-if="thumbnailUrl" class="page-header-image">
                    <img :src="photo" :alt="title" />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        photo: String,
        title: String,
        content: String,
        contentLink: String,
        linkX: String,
    },
    data() {
        return {
            isSingle: false, // Set this based on your logic
            headerTitle: 'Proba test',
            headerContent: 'content test',
            thumbnailUrl: require('@/assets/404.jpg'),
        };
    },

    methods: {
    },
};
</script>

<style>
@import '@/css/main.css';
</style>