<template>
    <NavbarMain />

    <PresentationSection v-if="currentLanguage === 'pl'" :photo="first_section_image_pl" :title="first_section_title_pl"
        :content="first_section_content_pl" />
    <PresentationSection v-else :photo="first_section_image_en" :title="first_section_title_en"
        :content="first_section_content_en" />

    <CardSection v-if="currentLanguage === 'pl'" :content-title="second_section_title_pl"
        :content-sentance="second_section_content_pl" class="text-center" />
    <CardSection v-else :content-title="second_section_title_en" :content-sentance="second_section_content_en"
        class="text-center" />

    <WidePhotoComponent v-if="currentLanguage === 'pl'" :photo="third_section_image_pl"
        :content="third_section_content_pl" />
    <WidePhotoComponent v-else :photo="third_section_image_en" :content="third_section_content_en" />

    <FooterPage />
</template>

<script>
import axios from 'axios';

import CardSection from '@/components/CardSection.vue';
import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import PresentationSection from '@/components/PresentationSection.vue';
import WidePhotoComponent from '@/components/WidePhotoComponent.vue';

export default {
    components: {
        NavbarMain,
        PresentationSection,
        FooterPage,
        WidePhotoComponent,
        CardSection
    },
    data() {
        return {
            strategyData: {
                items_pl: [{ first_section_title: '', first_section_content: '', first_section_image: '', second_section_title: '', second_section_content: '', third_section_image: '', third_section_content: '' }],
                items_en: [{ first_section_title: '', first_section_content: '', first_section_image: '', second_section_title: '', second_section_content: '', third_section_image: '', third_section_content: '' }]
            },
            first_section_title_pl: '',
            first_section_content_pl: '',
            first_section_image_pl: '',
            second_section_title_pl: '',
            second_section_content_pl: '',
            third_section_image_pl: '',
            third_section_content_pl: '',


            first_section_title_en: '',
            first_section_content_en: '',
            first_section_image_en: '',
            second_section_title_en: '',
            second_section_content_en: '',
            third_section_image_en: '',
            third_section_content_en: '',
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = '343ea0306525d6efa98f3d15cdb2f0a5db59df4f';
        const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;

        axios.get(`${API_URL}/api/strategy-content`, {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.strategyData = response.data || { items_pl: [], items_en: [] };

                if (this.strategyData.items_pl[0].active === true) {
                    this.first_section_title_pl = this.strategyData.items_pl[0].first_section_title;
                    this.first_section_content_pl = this.strategyData.items_pl[0].first_section_content;
                    this.first_section_image_pl = API_URL + this.strategyData.items_pl[0].first_section_image;
                    this.second_section_title_pl = this.strategyData.items_pl[0].second_section_title;
                    this.second_section_content_pl = this.strategyData.items_pl[0].second_section_content;
                    this.third_section_image_pl = API_URL + this.strategyData.items_pl[0].third_section_image;
                    this.third_section_content_pl = this.strategyData.items_pl[0].third_section_content;
                }
                if (this.strategyData.items_en[0].active === true) {
                    this.first_section_title_en = this.strategyData.items_en[0].first_section_title;
                    this.first_section_content_en = this.strategyData.items_en[0].first_section_content;
                    this.first_section_image_en = API_URL + this.strategyData.items_en[0].first_section_image;
                    this.second_section_title_en = this.strategyData.items_en[0].second_section_title;
                    this.second_section_content_en = this.strategyData.items_en[0].second_section_content;
                    this.third_section_image_en = API_URL + this.strategyData.items_en[0].third_section_image;
                    this.third_section_content_en = this.strategyData.items_en[0].third_section_content;
                }
            })
            .catch(error => {
                console.error('Error fetching Strategy data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}

</script>

<style>
.text-center div h2 {
    display: grid;
  text-align: center;
}

</style>