<template>
    <section :class="'page-section section-content-with-photo ' ">
        <div class="container-fluid">

            <div class="block-wrapper">
                <div class="image-wrapper">
                    <img :src="contentPhoto_image" alt="">
                </div>

                <div class="content-wrapper" style="text-align: left;">
                    <div v-html="contentPhoto_title" />
                    <div v-html="contentPhoto_content1" />
                    <div v-html="contentPhoto_content2" />
                </div>
            </div>

        </div>
    </section>
</template>
  
<script>
export default {
    props: {
    contentPhoto_image: String,
    contentPhoto_title: String,
    contentPhoto_content1: String,
    contentPhoto_content2: String,

  },
    data() {
        return {

            image: require('@/assets/404.jpg'),
        };
    }
};
</script>
  
<style>
@import '@/css/contentWithPhotoSection.css';
</style>