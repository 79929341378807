<template>
    <NavbarMain :first-page="false" />
  
    <GdprPage v-if="currentLanguage === 'pl'" :section_title="section_title_pl" :gdpr_policy="gdpr_policy_pl" /> 
    <GdprPage v-else :section_title="section_title_en" :gdpr_policy="gdpr_policy_en" />

    <FooterPage />
</template>

<script>
import axios from 'axios';

import NavbarMain from '../components/NavbarMain.vue'
import FooterPage from '../components/FooterPage.vue'
import GdprPage from '@/components/GdprPage.vue';

export default {
    components: {
        NavbarMain,
        FooterPage,
        GdprPage
    },
    data() {

        return {
            gdprData: {
                items_pl: [{ section_title: '', gdpr_policy: '' }],
                items_en: [{ section_title: '', gdpr_policy: '' }],
            },
            section_title_pl: '',
            section_title_en: '',
            gdpr_policy_pl: '',
            gdpr_policy_en: '',

        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = '343ea0306525d6efa98f3d15cdb2f0a5db59df4f';
        const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;

        axios.get(`${API_URL}/api/gdpr-content`, {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.gdprData = response.data || { items_pl: [], items_en: [] };

                if (this.gdprData.items_pl[0].active === true) {
                    this.section_title_pl = this.gdprData.items_pl[0].section_title;
                    this.gdpr_policy_pl = this.gdprData.items_pl[0].gdpr_policy;
                }
                if (this.gdprData.items_en[0].active === true) {
                    this.section_title_en = this.gdprData.items_en[0].section_title;
                    this.gdpr_policy_en = this.gdprData.items_en[0].gdpr_policy;
                }
            })
            .catch(error => {
                console.error('Error fetching GDPR data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
</script>

<style></style>