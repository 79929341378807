<template>
    <NavbarMain/>

    <PresentationSection v-if="currentLanguage === 'pl'" :photo="first_section_image_pl" :title="first_section_title_pl" :content="first_section_content_pl"/>
    <PresentationSection v-else :photo="first_section_image_en" :title="first_section_title_en" :content="first_section_content_en"/>

    <CardSection v-if="currentLanguage === 'pl'" :content-title="second_section_title_pl" :content-sentance="second_section_content_pl" :button-text="second_section_link_content_pl" :button-link="second_section_link_pl"/>
    <CardSection v-else :content-title="second_section_title_en" :content-sentance="second_section_content_en" :button-text="second_section_link_content_en" :button-link="second_section_link_en"/>

    <ContentWithPhoto v-if="currentLanguage === 'pl'" :content-photo_image="third_section_image_pl" :content-photo_title="third_section_title_pl" :content-photo_content1="third_section_content_pl"/>
    <ContentWithPhoto v-else :content-photo_image="third_section_image_en" :content-photo_title="third_section_title_en" :content-photo_content1="third_section_content_en"/>

    <WidePhotoComponent v-if="currentLanguage === 'pl'" :photo="fourth_section_image_pl" :content="fourth_section_content_pl" />
    <WidePhotoComponent v-else :photo="fourth_section_image_en" :content="fourth_section_content_en" />

    <FooterPage/>    
</template>
 
<script>
import axios from 'axios';
import CardSection from '@/components/CardSection.vue';
import ContentWithPhoto from '@/components/ContentWithPhoto.vue';
import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import PresentationSection from '@/components/PresentationSection.vue';
import WidePhotoComponent from '@/components/WidePhotoComponent.vue';
 
export default {
    components: {
    NavbarMain,
    PresentationSection,
    FooterPage,
    ContentWithPhoto,
    WidePhotoComponent,
    CardSection
},
    data() {
        return {
            aboutUsData: {
                items_pl: [{first_section_title: '', first_section_content: '', first_section_image: '',second_section_title: '', second_section_content: '', second_section_link: '', second_section_link_content: '', third_section_image: '', third_section_title: '', third_section_content: '', fourth_section_image: '', fourth_section_content: ''}],
                items_en: [{first_section_title: '', first_section_content: '', first_section_image: '',second_section_title: '', second_section_content: '', third_section_image: '', third_section_title: '', third_section_content: '', fourth_section_image: '', fourth_section_content: ''}]
            },
            first_section_title_pl: '',
            first_section_content_pl: '',
            first_section_image_pl: '',
            second_section_title_pl: '',
            second_section_content_pl: '',
            second_section_link_pl: '',
            second_section_link_content_pl: '',
            third_section_image_pl: '',
            third_section_title_pl: '',
            third_section_content_pl: '',
            fourth_section_image_pl: '',
            fourth_section_content_pl: '',
        
            first_section_title_en: '',
            first_section_content_en: '',
            first_section_image_en: '',
            second_section_title_en: '',
            second_section_content_en: '',
            second_section_link_en: '',
            second_section_link_content_en: '',
            third_section_image_en: '',
            third_section_title_en: '',
            third_section_content_en: '',
            fourth_section_image_en: '',
            fourth_section_content_en: '',
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = '343ea0306525d6efa98f3d15cdb2f0a5db59df4f';
        const API_URL = process.env.VUE_APP_WEBSITE_HOSTNAME;
 
        axios.get(`${API_URL}/api/about-us-content`, {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.aboutUsData = response.data || { items_pl: [], items_en: [] };
                console.log(this.aboutUsData)
            
                if (this.aboutUsData.items_pl[0].active === true) {
                    this.first_section_title_pl = this.aboutUsData.items_pl[0].first_section_title;
                    this.first_section_content_pl = this.aboutUsData.items_pl[0].first_section_content;
                    this.first_section_image_pl = API_URL + this.aboutUsData.items_pl[0].first_section_image;
                    this.second_section_title_pl = this.aboutUsData.items_pl[0].second_section_title;
                    this.second_section_content_pl = this.aboutUsData.items_pl[0].second_section_content;
                    this.second_section_link_pl = this.aboutUsData.items_pl[0].second_section_link;
                    this.second_section_link_content_pl = this.aboutUsData.items_pl[0].second_section_link_content;
                    this.third_section_image_pl = API_URL + this.aboutUsData.items_pl[0].third_section_image;
                    this.third_section_title_pl = this.aboutUsData.items_pl[0].third_section_title;
                    this.third_section_content_pl = this.aboutUsData.items_pl[0].third_section_content;
                    this.fourth_section_image_pl = API_URL + this.aboutUsData.items_pl[0].forth_section_image;
                    this.fourth_section_content_pl = this.aboutUsData.items_pl[0].forth_section_content;
                }
                if (this.aboutUsData.items_en[0].active === true) {
                    this.first_section_title_en = this.aboutUsData.items_en[0].first_section_title;
                    this.first_section_content_en = this.aboutUsData.items_en[0].first_section_content;
                    this.first_section_image_en = API_URL + this.aboutUsData.items_en[0].first_section_image;
                    this.second_section_title_en = this.aboutUsData.items_en[0].second_section_title;
                    this.second_section_content_en = this.aboutUsData.items_en[0].second_section_content;
                    this.second_section_link_en = this.aboutUsData.items_en[0].second_section_link;
                    this.second_section_link_content_en = this.aboutUsData.items_en[0].second_section_link_content;
                    this.third_section_image_en = API_URL + this.aboutUsData.items_en[0].third_section_image;
                    this.third_section_title_en = this.aboutUsData.items_en[0].third_section_title;
                    this.third_section_content_en = this.aboutUsData.items_en[0].third_section_content;
                    this.fourth_section_image_en = API_URL + this.aboutUsData.items_en[0].forth_section_image;
                    this.fourth_section_content_en = this.aboutUsData.items_en[0].forth_section_content;
                }
                console.log(this.first_section_image_en)
            })
            .catch(error => {
                console.error('Error fetching About US data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
 
</script>
 
<style>
 
</style>
