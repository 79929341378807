<template>
    <section :class="'page-section section-wide-photo-statement ' + blockClassName">
        <div class="container-fluid">
            <div class="block-wrapper">
                <div class="image-wrapper">
                    <!-- <img :src="image" alt="" /> -->
                    <img :src="photo" alt=""/>
                </div>
                <div class="content-wrapper">
                    <h4 :style="{ textAlign: 'left', fontWeight: 800, color: 'white'}">
                       <div v-html="content"/> 
                       <!-- {{ content }} -->
                    </h4>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    props: {
        photo: String,
        title: String,
        content: String,
    },
    data() {
        return {
            image: require('@/assets/404.jpg'),
        }
    },
}

</script>

<style>
@import '@/css/widePhotoSection.css';
</style>